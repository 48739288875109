export const EVENTS_FETCH_REQUESTED = "EVENTS_FETCH_REQUESTED";
export const EVENTS_FETCHED_SUCCESSFULLY = "EVENTS_FETCHED_SUCCESSFULLY";
export const EVENTS_FETCH_HAS_FAILED = "EVENTS_FETCH_HAS_FAILED";
export const EVENT_SELECT_REQUESTED = "EVENT_SELECT_REQUESTED";
export const EVENT_SELECT = "EVENT_SELECT";
export const EVENT_SELECTED_SUCCESSFULLY = "EVENT_SELECTED_SUCCESSFULLY";
export const EVENT_SELECT_FAILED = "EVENT_SELECT_FAILED";
export const EVENT_DESELECT_REQUESTED = "EVENT_DESELECT_REQUESTED";
export const EVENT_DESELECT = "EVENT_DESELECT_REQUESTED";
export const EVENT_DESELECTED_SUCCESSFULLY = "EVENT_DESELECTED_SUCCESSFULLY";
export const EVENT_DESELECT_FAILED = "EVENT_DESELECT_FAILED";
